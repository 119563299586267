.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background: linear-gradient(180deg, #3F3F3F 0%, #3B3B3B 13.02%, #232323 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  display:block;
  padding: 15px;
  border-radius: 5px;
  text-decoration: none;
  background-color: #dddddd;
  color: #000;
  margin-top: 50px;
}

.button:hover {
  opacity: 0.6;
}
